import { render, staticRenderFns } from "./App.vue?vue&type=template&id=13b1e436&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.7.1_vue-template-compiler@2.7.10_webpack@5.74.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports